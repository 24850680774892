import { Icon } from "@iconify/react";
import React, { useState } from "react";
import ModalImage from "react-modal-image";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";

export default function MasonryGallery() {
  const [active, setActive] = useState("all");
  const [itemShow, setItemShow] = useState(10);

  const portfolioData = [
    {
      title: "Events Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/events/events_1.jpg",
      srcLg: "/images/portfolio/events/events_1_lg.jpg",
      category: "events",
      height: 299,
    },
    {
      title: "Commercial Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/commercial/commercial_1.jpg",
      srcLg: "/images/portfolio/commercial/commercial_1_lg.jpg",
      category: "commercial",
      height: 299,
    },
    {
      title: "Wedding Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/wedding/wedding_1.jpg",
      srcLg: "/images/portfolio/wedding/wedding_1_lg.jpg",
      category: "wedding",
      height: 622,
    },
    {
      title: "Portrait Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/portrait/portrait_1.jpg",
      srcLg: "/images/portfolio/portrait/portrait_1_lg.jpg",
      category: "portrait",
      height: 299,
    },
    {
      title: "Sports Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/sports/sports_1.jpg",
      srcLg: "/images/portfolio/sports/sports_1_lg.jpg",
      category: "sports",
      height: 622,
    },
    {
      title: "Landscape Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/landscape/landscape_1.jpg",
      srcLg: "/images/portfolio/landscape/landscape_1_lg.jpg",
      category: "landscape",
      height: 299,
    },
    {
      title: "Events Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/events/events_2.jpg",
      srcLg: "/images/portfolio/events/events_2_lg.jpg",
      category: "events",
      height: 622,
    },
    {
      title: "Commercial Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/commercial/commercial_2.jpg",
      srcLg: "/images/portfolio/commercial/commercial_2_lg.jpg",
      category: "commercial",
      height: 299,
    },
    {
      title: "Wedding Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/wedding/wedding_2.jpg",
      srcLg: "/images/portfolio/wedding/wedding_2_lg.jpg",
      category: "wedding",
      height: 622,
    },
    {
      title: "Portrait Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/portrait/portrait_2.jpg",
      srcLg: "/images/portfolio/portrait/portrait_2_lg.jpg",
      category: "portrait",
      height: 299,
    },
    {
      title: "Sports Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/sports/sports_2.jpg",
      srcLg: "/images/portfolio/sports/sports_2_lg.jpg",
      category: "sports",
      height: 622,
    },
    {
      title: "Landscape Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/landscape/landscape_2.jpg",
      srcLg: "/images/portfolio/landscape/landscape_2_lg.jpg",
      category: "landscape",
      height: 299,
    },
    {
      title: "Events Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/events/events_3.jpg",
      srcLg: "/images/portfolio/events/events_3_lg.jpg",
      category: "events",
      height: 622,
    },
    {
      title: "Commercial Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/commercial/commercial_3.jpg",
      srcLg: "/images/portfolio/commercial/commercial_3_lg.jpg",
      category: "commercial",
      height: 299,
    },
    {
      title: "Wedding Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/wedding/wedding_3.jpg",
      srcLg: "/images/portfolio/wedding/wedding_3_lg.jpg",
      category: "wedding",
      height: 622,
    },
    {
      title: "Portrait Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/portrait/portrait_3.jpg",
      srcLg: "/images/portfolio/portrait/portrait_3_lg.jpg",
      category: "portrait",
      height: 622,
    },
    {
      title: "Sports Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/sports/sports_3.jpg",
      srcLg: "/images/portfolio/sports/sports_3_lg.jpg",
      category: "sports",
      height: 622,
    },
    {
      title: "Landscape Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/landscape/landscape_3.jpg",
      srcLg: "/images/portfolio/landscape/landscape_3_lg.jpg",
      category: "landscape",
      height: 622,
    },
    {
      title: "Events Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/events/events_4.jpg",
      srcLg: "/images/portfolio/events/events_4_lg.jpg",
      category: "events",
      height: 299,
    },
    {
      title: "Commercial Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/commercial/commercial_4.jpg",
      srcLg: "/images/portfolio/commercial/commercial_4_lg.jpg",
      category: "commercial",
      height: 299,
    },
    {
      title: "Wedding Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/wedding/wedding_4.jpg",
      srcLg: "/images/portfolio/wedding/wedding_4_lg.jpg",
      category: "wedding",
      height: 622,
    },
    {
      title: "Portrait Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/portrait/portrait_4.jpg",
      srcLg: "/images/portfolio/portrait/portrait_4_lg.jpg",
      category: "portrait",
      height: 299,
    },
    {
      title: "Sports Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/sports/sports_4.jpg",
      srcLg: "/images/portfolio/sports/sports_4_lg.jpg",
      category: "sports",
      height: 299,
    },
    {
      title: "Landscape Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/landscape/landscape_4.jpg",
      srcLg: "/images/portfolio/landscape/landscape_4_lg.jpg",
      category: "landscape",
      height: 299,
    },
    {
      title: "Events Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/events/events_5.jpg",
      srcLg: "/images/portfolio/events/events_5_lg.jpg",
      category: "events",
      height: 622,
    },
    {
      title: "Commercial Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/commercial/commercial_5.jpg",
      srcLg: "/images/portfolio/commercial/commercial_5_lg.jpg",
      category: "commercial",
      height: 299,
    },
    {
      title: "Wedding Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/wedding/wedding_5.jpg",
      srcLg: "/images/portfolio/wedding/wedding_5_lg.jpg",
      category: "wedding",
      height: 299,
    },
    {
      title: "Portrait Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/portrait/portrait_5.jpg",
      srcLg: "/images/portfolio/portrait/portrait_5_lg.jpg",
      category: "portrait",
      height: 622,
    },
    {
      title: "Sports Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/sports/sports_5.jpg",
      srcLg: "/images/portfolio/sports/sports_5_lg.jpg",
      category: "sports",
      height: 299,
    },
    {
      title: "Landscape Photography",
      subtitle: "View Large",
      href: "/",
      src: "/images/portfolio/landscape/landscape_5.jpg",
      srcLg: "/images/portfolio/landscape/landscape_5_lg.jpg",
      category: "landscape",
      height: 622,
    },
  ];
  const categoryMenu = [
    {
      title: "Wedding",
      category: "wedding",
    },
    {
      title: "Portrait",
      category: "portrait",
    },
    {
      title: "Sports",
      category: "sports",
    },
    {
      title: "Commercial",
      category: "commercial",
    },
    {
      title: "Landscape",
      category: "landscape",
    },
    {
      title: "Events",
      category: "events",
    },
  ];
  return (
    <>
      <Div className="container" id="portfolio">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Some recent work" subtitle="Our Portfolio" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === "all" ? "active" : ""}>
                <span onClick={() => setActive("all")}>All</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.category ? "active" : ""}
                  key={index}
                >
                  <span onClick={() => setActive(item.category)}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
      </Div>
      <Spacing lg="90" md="45" />
      <Div className="cs-masonry_4_col">
        {portfolioData.slice(0, itemShow).map((item, index) => (
          <Div
            className={`${
              active === "all"
                ? ""
                : !(active === item.category)
                ? "d-none"
                : ""
            }`}
            key={index}
          >
            <Div
              className="cs-portfolio cs-style1 cs-type2"
              style={{ height: `${item.height}px` }}
            >
              <Div className="cs-lightbox_item">
                <ModalImage
                  small={item.src}
                  large={item.srcLg}
                  alt={item.title}
                />
              </Div>
              <Div className="cs-portfolio_hover" />
              <span className="cs-plus" />
              <Div
                className="cs-portfolio_bg cs-bg"
                style={{ backgroundImage: `url("${item.src}")` }}
              />
              <Div className="cs-portfolio_info">
                <Div className="cs-portfolio_info_bg cs-accent_bg" />
                <h2 className="cs-portfolio_title">{item.title}</h2>
                <Div className="cs-portfolio_subtitle">{item.subtitle}</Div>
              </Div>
            </Div>
          </Div>
        ))}
      </Div>
      <Div className="container">
        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 4)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
    </>
  );
}
