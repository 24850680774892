import React from 'react'
import Div from '../Div'
import './logolist.scss'

export default function LogoList1() {
  const partnerLogos = [
    {
      src: '/images/clients/bpc.png', 
      alt:'Birgunj Public College'
    },
    {
      src: '/images/clients/benatutrals.png', 
      alt:'Be Natural Icecream'
    },
    {
      src: '/images/clients/bm.png', 
      alt:'Birgunj Maha Nagarpalika'
    },
    {
      src: '/images/clients/greencity.png', 
      alt:'Birgunj Green City'
    },
    {
      src: '/images/clients/empclg.png', 
      alt:'Emperial College'
    },
    {
      src: '/images/clients/gurukul.png', 
      alt:'Gurukul Academy'
    },
    {
      src: '/images/clients/jjss.png', 
      alt:'Jeevan Jyoti Secondary School'
    },
    {
      src: '/images/clients/navadeep.png', 
      alt:'Navadeep Academy'
    },
    {
      src: '/images/clients/nicci.png', 
      alt:'NICCI'
    },
    {
      src: '/images/clients/piet.png', 
      alt:'P.I.E.T'
    }, 
    {
      src: '/images/clients/rt.png', 
      alt:'Round Table Nepal'
    } 
  ]
  return (
    <Div className="cs-partner_logo_wrap">
      {partnerLogos.map((partnerLogo, index)=><div className="cs-partner_logo" key={index}><img src={partnerLogo.src} alt={partnerLogo.alt} /></div>)}
    </Div>
  )
}
