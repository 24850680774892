import { Route, Routes } from 'react-router-dom';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import PhotographyAgencyHome from './components/Pages/PhotographyAgencyHome';
import Layout from './components/Layout';
import ServiceDetailsPage from './components/Pages/ServiceDetailsPage'
import ShowcasePortfolioHome from './components/Pages/ShowcasePortfolioHome'
import ComingSoon from './components/Pages/ComingSoon';

function App() {
  return (
    <>
      <Routes>
      {/* <Route path="/" index element={<ComingSoon />} /> */}
        <Route path="/" element={<Layout />}>
          <Route index element={<PhotographyAgencyHome />} />
          <Route path='/service/:name' element={<ServiceDetailsPage />} />
          <Route path='/portfolio' element={<ShowcasePortfolioHome />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
        
      </Routes>
    </>
  );
}

export default App;
