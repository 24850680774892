import React from 'react'
import Div from '../Div'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function FunFact2({ data, variant, bgUrl }) {
  

  return (
    <Div className={variant ? `cs-funfact_wrap_2 ${variant}` : 'cs-funfact_wrap_2'}>
      {bgUrl && <div className="cs-funfact_shape" style={{ backgroundImage: `url(${bgUrl})` }}></div>}
      <Div className="cs-funfacts">
      <div style={{ width: 200, height: 200 }}>
        <CircularProgressbar value={800} maxValue={1000} text='800+ Shoots'styles={buildStyles({
          textSize: '10px',
          textColor: '#fff',
          pathColor: '#ff4a17',
        })}/>
      </div>
      <div style={{ width: 200, height: 200 }}>
        <CircularProgressbar value={600} maxValue={800} text='600+ Clients' styles={buildStyles({
          textSize: '10px',
          textColor: '#fff',
          pathColor: '#ff4a17',
        })}/>
      </div>
      <div style={{ width: 200, height: 200 }}>
        <CircularProgressbar value={15} maxValue={18} text='15+ Members' styles={buildStyles({
          textSize: '10px',
          textColor: '#fff',
          pathColor: '#ff4a17',
        })}/>
      </div>
      </Div>

      {/* <Div className="cs-funfacts">
        {data.map((item, index) => (
          <Div key={index}>
            <Div className="cs-funfact cs-style2">
              <Div className="cs-funfact_number cs-primary_font cs-semi_bold cs-primary_color"><span className="odometer" />{item.factNumber}<span className="cs-plus">+</span></Div>
              <h3 className="cs-funfact_title">{item.title}</h3>
            </Div>
          </Div>
        ))}
      </Div> */}
    </Div>
  )
}
