import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Card from '../Card';
import Div from '../Div';
import FunFact2 from '../FunFact/FunFact2';
import FunFact from '../FunFact/';
import MasonryGallery from '../Gallery/MasonryGallery';
import Hero2 from '../Hero/Hero2';
import LogoList from '../LogoList/index';
import LogoList1 from '../LogoList1/index';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"




export default function PhotographyAgencyHome() {

  function openPopupBox(){
    const content = <img src="images/popup/img.png" />
      PopupboxManager.open({ 
        content,
        config:{
          overlayClose: true,
          escClose: true,
          fadeOut: true,
          titleBar: {
            enable: false,
            text:"One"
          },
          style:{
            backgroundColor: '#000000',
          }
        },
        
       })
  }


  pageTitle('Photography Agency In Birgunj');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   openPopupBox()
  // }, [])

  
  const heroData = [
    {
      title: 'Weddings',
      imageUrl: './images/hero/wedding.png',
      href: '/',
    },
    {
      title: 'Events',
      imageUrl: './images/hero/events.png',
      href: '/',
    },
    {
      title: 'Commercial',
      imageUrl: './images/hero/commercial.png',
      href: '/',
    },
    {
      title: 'Portraits',
      imageUrl: './images/hero/portrait.png',
      href: '/',
    },
    {
      title: 'Pre-Weds',
      imageUrl: './images/hero/pre-weds.png',
      href: '/',
    },
  ];
  const funfaceData = [
    {
      title: 'Shoots',
      factNumber: '800',
    },
    {
      title: 'Happy clients',
      factNumber: '600',
    },
    {
      title: 'Team members',
      factNumber: '15',
    },
  ];

  
  return (
    <>
      <PopupboxContainer />
      {/* Start Hero Section */}
      <Hero2 data={heroData} phoneNumber="+977 9804214912" email="info@picslens.com.np" />
      {/* End Hero Section */}
      
      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container" id="about">
        <Div className="row align-items-center cs-column_reverse_lg">
          <Div className="col-lg-5">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img
                src="/images/picslens.gif"
                alt="About"
                className="cs-w100 cs-radius_5"
              />
            </Div>
          </Div>
          <Div className="col-lg-6 offset-lg-1">
            <SectionHeading
              title="We are Nepal based photography <br/>agency"
              subtitle="About Us"
              bodyText="PicsLens captures life's unforgettable moments with expert precision. Specializing in diverse photography and videography services, we create images that speak louder than words."
              btnText="Learn More"
              btnLink="/about"
            />
            <Spacing lg="0" md="40" />
          </Div>
        </Div>
      </Div>
      {/* End About Section */}
      
      {/* Start Services Section */}
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we can provide you"
                subtitle="What Can We Do"
                btnText="See All Services"
                btnLink="/service"
                bodyText="PicsLens offers comprehensive photography and videography services, from product shoots to event coverage, crafting stunning visual narratives tailored to each client's unique needs."
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6">
                <Card
                    title="Event Photography"
                    link="/service/events"
                    src="/images/services/event.gif"
                    alt="event photography gif"
                  />
                  <Spacing lg="20" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Wedding Photography"
                    link="/service/wedding"
                    src="/images/services/wedding.gif"
                    alt="wedding photoghraphy gif"
                  />
                  <Spacing lg="20" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                <Card
                    title="Commerical Photography"
                    link="/service/comm"
                    src="/images/services/commerce.gif"
                    alt="commercial photopraphy gif"
                  />
                  <Spacing lg="20" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Portrait Photography"
                    link="/service/portrait"
                    src="/images/services/portrait.gif"
                    alt="Portrait Photography gif"
                  />
                  <Spacing lg="20" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Sports Photography"
                    link="/service/sports"
                    src="/images/services/sports.gif"
                    alt="Sports Photography gif"
                  />
                  <Spacing lg="20" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                <Card
                    title="Short Film Shoot"
                    link="https://www.youtube.com/watch?v=oC20eG9nFDY"
                    src="/images/services/short-film.gif"
                    alt="Short Film Shoot gif"
                  />
                  <Spacing lg="20" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Pre Wedding Shoot"
                    link="/service/pre-wed"
                    src="/images/services/pre-wed.gif"
                    alt="Pre Wedding Shoot gif"
                  />
                  <Spacing lg="20" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                <Card
                    title="Maternity Photo Shoot"
                    link="/service/maternity"
                    src="/images/services/pregnancy.gif"
                    alt="Maternity Photo Shoot gif"
                  />
                  <Spacing lg="20" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile cs-hidden_desktop">
                <Card
                    title="Product Photography"
                    link="/service/product"
                    src="/images/services/product.gif"
                    alt="Product Photography gif"
                  />
                  <Spacing lg="20" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                <Card
                    title="Office Space Photography"
                    link="/service/office"
                    src="/images/services/office.gif"
                    alt="Office Space Photography gif"
                  />
                  <Spacing lg="20" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                <Card
                    title="Product Photography"
                    link="/service/product"
                    src="/images/services/product.gif"
                    alt="Product Photography gif"
                  />
                  <Spacing lg="20" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 ">
                <Card
                    title="NGO Shoots"
                    link="/service/ngo"
                    src="/images/services/ngo.gif"
                    alt="NGO Shoots gif"
                  />
                  <Spacing lg="20" md="30" />
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Services Section */}

      {/* Start Gallery Section */}
      <Spacing lg="145" md="80" />
      <MasonryGallery />
      {/* End Gallery Section */}




      {/* Start Pricing Section */}
      <Spacing lg="140" md="70" />
      <Div className="container" id="clients">
        <SectionHeading
          title="Providing best <br/>services for client"
          subtitle="Our Clients"
          bodyText=""
        />
        <Spacing lg="85" md="40" />

      {/* Start FunFact Section */}
      <Div className="container">
        {/* <FunFact variant="cs-type2" data={funfaceData} title="Our Title" subtitle="Our Subtitle" /> */}
        <FunFact2 data={funfaceData} variant="cs-style2" />
      </Div>
      {/* End FunFact Section */}

      <Spacing lg="140" md="80" />
        <LogoList />
        <LogoList1 />
      </Div>
      <Spacing lg="125" md="55" />
      {/* End Pricing Section */}




      {/* Start Contact Section */}
      
      <Div className="cs-gradient_bg_1" id="contact-us">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <Spacing lg="100" md="80" />
              <form action="#" className="row">
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Full Name*</label>
                  <input type="text" className="cs-form_field" />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Email*</label>
                  <input type="text" className="cs-form_field" />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Project Type*</label>
                  <input type="text" className="cs-form_field" />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Mobile*</label>
                  <input type="text" className="cs-form_field" />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-12">
                  <label className="cs-primary_color">Mobile*</label>
                  <textarea
                    cols="30"
                    rows="7"
                    className="cs-form_field"
                  ></textarea>
                  <Spacing lg="25" md="25" />
                </Div>
                <Div className="col-sm-12">
                  <button className="cs-btn cs-style1">
                    <span>Send Message</span>
                    <Icon icon="bi:arrow-right" />
                  </button>
                </Div>
              </form>
              <Spacing lg="100" md="60" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Div
                className="cs-google_map cs-type1 cs-bg"
                data-src="assets/img/map_img_1.jpeg"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8095.419314304291!2d84.87052597975739!3d27.01755367507148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399355b005c9cde7%3A0x40d9beeb539e5e5c!2sPics%20Lens%20Photography%20%26%20Videography!5e0!3m2!1sen!2snp!4v1692863746848!5m2!1sen!2snp"
                  allowFullScreen
                  title="Google Map"
                />
              </Div>
              <Spacing lg="0" md="80" />
            </Div>
          </Div>
        </Div>
      </Div>
      {/* Start Contact Section */}
    </>
  );
}
