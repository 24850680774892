import React from 'react'
import Div from '../Div'
import './logolist.scss'

export default function LogoList() {
  const partnerLogos = [
    {
      src: '/images/clients/mm.png', 
      alt:'Media Mitra Technologies'
    },
    {
      src: '/images/clients/adsbee.png', 
      alt:'Ads Bee Media Pvt. Ltd.'
    },
    {
      src: '/images/clients/gss.png', 
      alt:'Gautam Secondary School'
    },
    {
      src: '/images/clients/mediciti.png', 
      alt:'Narayani Vayodhya Mediciti Hospital'
    },
    {
      src: '/images/clients/jccon.png', 
      alt:'Jagdamba Concreto Pvt. Ltd.'
    },
    {
      src: '/images/clients/gyanda.png', 
      alt:'Gyanda Academy Pvt. Ltd.'
    },
    {
      src: '/images/clients/ni.png', 
      alt:'National Infotech College'
    },
    {
      src: '/images/clients/wow.png', 
      alt:'Worlds of Wonder'
    },
    {
      src: '/images/clients/komaki.png', 
      alt:'Komaki EV Showroom'
    },
    {
      src: '/images/clients/nyef.png', 
      alt:'Nepalese Youth Enterprenuers Fourm, Birgunj Chapter'
    }
  ]
  return (
    <Div className="cs-partner_logo_wrap">
      {partnerLogos.map((partnerLogo, index)=><div className="cs-partner_logo" key={index}><img src={partnerLogo.src} alt={partnerLogo.alt} /></div>)}
    </Div>
  )
}
