import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Div from '../Div'
import Hero6 from '../Hero/Hero6'
import PageHeading from '../PageHeading'
import Spacing from '../Spacing';
import { Icon } from '@iconify/react';

export default function ServiceDetailsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const params = useParams();
  var showcaseData = []
  var sectionTitle = ""
  var sectionSubtitle = ""

  switch (params.name) {
    case 'events':
      sectionTitle = "Event Photography"
      sectionSubtitle = "We Make Your Memories Last"
      showcaseData = require('../Data/Events.json')
      break;
    case 'wedding':
      sectionTitle = "Wedding Photography"
      sectionSubtitle = "We Capture Your Love Story"
      showcaseData = require('../Data/Wedding.json')
      break;
    case 'comm':
      sectionTitle = "Commercial Photography"
      sectionSubtitle = "We Showcase Your Brand's Best"
      showcaseData = require('../Data/Commercial.json')
      break;
    case 'portrait':
      sectionTitle = "Portrait Photography"
      sectionSubtitle = "We Frame Your True Essence"
      showcaseData = require('../Data/Portrait.json')
      break;
    case 'sports':
      sectionTitle = "Sports Photography"
      sectionSubtitle =  "We Freeze Your Dynamic Moments"
      showcaseData = require('../Data/Sports.json')
      break;
    case 'short-film':
      sectionTitle = "Short Film Shoot"
      sectionSubtitle = "We Visualize Your Story"
      showcaseData = require('../Data/ShortFilm.json')
      break;
    case 'pre-wed':
      sectionTitle = "Pre Wedding Photography"
      sectionSubtitle = "We Capture Your Excitement"
      showcaseData = require('../Data/PreWed.json')
      break;
    case 'maternity':
      sectionTitle = "Maternity Photography"
      sectionSubtitle = "We Celebrate Your Journey"
      showcaseData = require('../Data/Maternity.json')
      break;
    case 'product':
      sectionTitle = "Product Photography"
      sectionSubtitle = "We Present Your Creations"
      showcaseData = require('../Data/Product.json')
      break;
    case 'office':
      sectionTitle = "Office Space Photography"
      sectionSubtitle = "We Capture Professional Spaces"
      showcaseData = require('../Data/Office.json')
      break;
    case 'ngo':
      sectionTitle = "NGO Shoots"
      sectionSubtitle = "We Amplify Your Impact"
      showcaseData = require('../Data/NGO.json')
      break;
  
    default:
      break;
  }

  const heroSocialLinks = [
    {
      name: '',
      links: '/',
    },
    {
      name: '',
      links: '/',
    },
  ];



  pageTitle(sectionTitle.toUpperCase());
  


  return (
    <>
      <PageHeading id='pageHeading'
        title={sectionTitle.toUpperCase()}
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
        subTitle={sectionSubtitle}
      />
      <Hero6
          heroSocialLinks={heroSocialLinks}
          socialLinksHeading="Follow Us"
          showcaseData={showcaseData}
        />
            {/* Start Contact Section */}
      
            <Div className="cs-gradient_bg_1" id="contact-us">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <Spacing lg="100" md="80" />
              <form action="#" className="row">
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Full Name*</label>
                  <input type="text" className="cs-form_field" />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Email*</label>
                  <input type="text" className="cs-form_field" />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Project Type*</label>
                  <input type="text" className="cs-form_field" />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Mobile*</label>
                  <input type="text" className="cs-form_field" />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-12">
                  <label className="cs-primary_color">Mobile*</label>
                  <textarea
                    cols="30"
                    rows="7"
                    className="cs-form_field"
                  ></textarea>
                  <Spacing lg="25" md="25" />
                </Div>
                <Div className="col-sm-12">
                  <button className="cs-btn cs-style1">
                    <span>Send Message</span>
                    <Icon icon="bi:arrow-right" />
                  </button>
                </Div>
              </form>
              <Spacing lg="100" md="60" />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Div
                className="cs-google_map cs-type1 cs-bg"
                data-src="assets/img/map_img_1.jpeg"
              >

                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8095.419314304291!2d84.87052597975739!3d27.01755367507148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399355b005c9cde7%3A0x40d9beeb539e5e5c!2sPics%20Lens%20Photography%20%26%20Videography!5e0!3m2!1sen!2snp!4v1692863746848!5m2!1sen!2snp"
                  allowFullScreen
                  title="Google Map"
                />
              </Div>
              <Spacing lg="0" md="80" />
            </Div>
          </Div>
        </Div>
      </Div>
      {/* Start Contact Section */}
    </>
  )
}
